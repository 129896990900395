import React from "react";
import SubPageBase from "../components/SubPageBase";
import img from "../images/department.png";
import Typography from "@material-ui/core/Typography";
import { Link } from "gatsby";

const Index = () => (
  <SubPageBase title="Kişisel Verilerin Korunması" img={img}>
    <div
      style={{
        paddingTop: "32px",
        width: "80%",
        margin: "auto",
      }}
    >
      <Typography variant="body1" gutterBottom>
        TURKSPORT SPOR ÜRÜNLERİ SAN. VE TİC. LTD. ŞTİ. (Decathlon) olarak özel hayatın gizliliğine saygı duyuyor ve önem
        veriyoruz. Bu kapsamda hazırladığımız aydınlatma metinlerine aşağıdaki linklerden erişebilirsiniz.
      </Typography>
      <Typography variant="body2" gutterBottom>
        Aday aydınlatma metnine ulaşmak için <Link to="/aday-aydinlatma-metni/">tıklayınız.</Link>
      </Typography>
      <Typography variant="body2" gutterBottom>
        İlgili kişi başvuru formuna ulaşmak için{" "}
        <a
          href="https://kesfet.decathlon.com.tr/cdn/decathlon_kvkk_basvuru_2020.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          tıklayınız.
        </a>
      </Typography>
      <Typography variant="body2" gutterBottom>
        Çerez politikasına ulaşmak için{" "}
        <a href="https://kesfet.decathlon.com.tr/cdn/cookie-policy.pdf" target="_blank" rel="noopener noreferrer">
          tıklayınız.
        </a>
      </Typography>
    </div>
  </SubPageBase>
);

export default Index;
